import React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo"

const Index = () => (
  <>
    <SEO title="Home"/>
    <div className="index">
      <p>I have never had any trouble believing in climate change, global warming, or whatever you want to call it. The
        scientific evidence is overwhelming. Galileo famously replied to Archbishop Piccolomini (or some other Vatican
        prelate), “And yet it moves.” Today we could just as well say, </p>
      <h1>And yet it changes.</h1>
      <p>But what to do about it? Logic rarely convinces anybody of anything. Climate change has become yet another
        vehicle for political polarization. If Al Gore said the Earth was round there would be political opposition
        insisting that the Earth was flat. It’s all so preposterous, so contemptible.</p>
      <p>I’ve created twenty thirty-second spots to run on television, the internet, and in theaters that profile a
        character I created: Admiral Horatio Horntower. He’s an admiral of a fleet of one and perhaps the last man on
        Earth. Hopefully it captures the absurdity and the desperation of our current situation. No pie graphs, no
        PowerPoint—just a blithering idiot played by one of my favorite actors, Bob Odenkirk.</p>
      <div style={{

        "textAlign": "right",
      }}>
        <p className={"credits"}>Directed by Errol Morris</p>
        <p className={"credits"} style={{
          "marginBottom": "3em",
        }}>Starring Bob Odenkirk</p>
      </div>


      <input type="email" placeholder="Your Email Address"/>

      <button type="button">Notify Me</button>

      <p style={{
        "marginTop": "1em",
      }}>Stay notified of updates. Join the next part of this campaign.</p>

    </div>
  </>
)

export default Index
